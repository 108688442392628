
import { createTheme } from '@material-ui/core/styles';

export const green = "#6D9680";
export const blue = "#2C3056";
export const lightBlue = "#9698aa";
export const orange = "#CE7652";
export const grey = "#DFDFDF";
export const white = "white";
export const fontFamily = [
  '"Inter"',
  '"Helvetica"',
  '"Arial"',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
  'sans-serif',
].join(',');

export default createTheme({
  typography: {
    fontSize: 13,  // TODO: https://material-ui.com/customization/typography/#responsive-font-sizes
    fontFamily: fontFamily,
  },
  palette: {
    primary: {
      main: green,
      contrastText: white,
    },
    secondary: {
      main: blue,
      contrastText: white,
    },
  },
});
