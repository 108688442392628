import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {blue, white} from "../theme";


const useStyles = makeStyles((theme) => ({
  Link: {
    textDecoration: "none",
    background: blue,
    padding: "15px 20px",
    width: 158,
    cursor: "pointer",

    '& h3': {
      fontFamily: "'Source Serif Pro', serif",
      fontSize: 24,
      color: white,
      margin: 0,
    }
  },
}))


const Link = ({ title, href }) => {

  const styles = useStyles();

  return (
    <div className={styles.Link} onClick={() => window.open(href, "_blank")}><h3>{title}</h3></div>
  )
}

export default Link;