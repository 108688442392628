import {makeStyles} from "@material-ui/core";
import {blue} from "../theme";
import React, {useCallback, useEffect} from "react";
import { useSpring, animated as a } from 'react-spring'

const VizParametric = ({play}) => {
  const height = 183;

  const styles = makeStyles((theme) => ({
    root: {
      position: "absolute",
      top: "0px",
      width: "100%",
      height: height,
      overflow: "hidden",

      '& line': {
        stroke: blue,
        strokeWidth: 10,
        opacity: 0.6,
        strokeLinecap: "round",
        strokeDasharray: "0.1px, 20px",
      },
    },
  }))();

  const [{ st }, set] = useSpring(() => ({ st: 0 }));
  // const interpT = st.to(o => `translate(0,${50 * Math.cos(o/500)})`);
  // const interpH = st.to(o => 40 * Math.cos(o/500)**2);
  // const interpY = st.to(o => height - 40 * Math.cos(o/500)**2);
  const onWheel = useCallback(e => play ? set({ st: st.animation.to + e.deltaY }) : null);

  useEffect(() => {
    let interval;
    if (play) {
      interval = setInterval(() => set({ st: st.animation.to - 2 }), 10)
    }
    return () => {
      clearInterval(interval)
    }
  });

  document.body.addEventListener('wheel', onWheel);

  return (
    <svg className={styles.root}>
      <g id="face" transform={"translate(75, 100)"}>
        {
          [
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,
            // 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,
          ].map((d, i)  => {
              return (
                <a.line key={i} // ,y1(t+i),x2(t+i)+20,y2(t+i)+20
                        x1={st.to(o => 0.4 * x1(o/20 + i*4) - 10)}
                        y1={st.to(o => 0.4 * y1(o/20 + i*4) - 10)}
                        x2={st.to(o => 0.4 * x2(o/20 + i*4) + 10)}
                        y2={st.to(o => 0.4 * y2(o/20 + i*4) + 10)}
                />
              )
            })
          })
        }
      </g>
    </svg>
  )
}

// function to change initial x co-ordinate of the line
const x1 = (t) => Math.sin(t/10)*125+Math.sin(t/20)*125+Math.sin(t/30)*125;

// function to change initial y co-ordinate of the line
const y1 = (t) => Math.cos(t/10)*125+Math.cos(t/20)*125+Math.cos(t/30)*125;

// function to change final x co-ordinate of the line
const x2 = (t) => Math.sin(t/15)*125+Math.sin(t/25)*125+Math.sin(t/35)*125;

// function to change final y co-ordinate of the line
const y2 = (t) => Math.cos(t/15)*125+Math.cos(t/25)*125+Math.cos(t/35)*125;

export default VizParametric;
