import React, { useEffect, useCallback } from 'react';
import { useSpring, animated as a } from 'react-spring';
import { useMediaQuery } from '@react-hook/media-query';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import theme, { green, grey, blue, lightBlue, orange, white, fontFamily } from './theme.js';
import Stack from '@mui/material/Stack';

import Card from './components/Card';
import Link from "./components/Link";
import Logo from "./components/Logo";
import VizBars from './components/VizBars';
import VizChain from './components/VizChain';
import VizIso from './components/VizIso';
import VizCircles from './components/VizCircles';
import VizParametric from './components/VizParametric';
import VizSpiderChart from "./components/VizSpiderChart";

import stripe from './images/stripe.png';
import logo from "./images/dsq-logo.png";

const maxWidth = 1087;
const maxHeight = 689;

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: fontFamily,
    maxWidth: maxWidth,
    margin: 0,
  },

  rootCompact: {
    width: "100%",
    overflowY: "scroll",
    transform: "none",
    margin: 0,
    marginTop: 0,
  },

  intro: {
    width: 365,
    height: "calc(100% - 80px)",
    background: `url(${stripe}) no-repeat`,
    backgroundSize: "100%",
    backgroundPosition: "bottom",
    backgroundColor: green,
    margin: 0,
    fontSize: "11px",
    padding: "40px 40px",
  },
  introCompact: {
    height: 400,
    width: "calc(100vw - 40px)",
    padding: "20px 20px",
    background: green,
  },
  introText: {
    fontSize: "13px !important",
    padding: "5px 0 22px 0",
    color: "white",
  },
  introTextCompact: {
    maxWidth: "100%",
  },
  dsq: {
    '& img': {
      display: "inline",
      height: 9,
    },
  },

}));

const App = () => {

  const styles = useStyles();

  const compact = useMediaQuery('only screen and (max-width: 500px)');  // screens with width below 500px see the mobile version
  const performance = false;  // if true - site will be optimised for performance TODO: detect low performance device
  const play = true  // !compact && !performance

  console.log("Rerender, compact:", compact, "styles:", `${styles.root} ${compact ? styles.rootCompact : ""}`)

  return (
    <ThemeProvider theme={theme}>
      <Stack alignItems={"center"} justifyContent={"center"} style={{ height: "100vh", width: "100vw" }}>
      <Stack className={`${styles.root} ${compact ? styles.rootCompact : ""}`} id={"app"} direction={compact ? "column" : "row"} spacing={2} alignItems={"center"}>
        <div className={`${styles.intro} ${compact ? styles.introCompact : ""}`}>
          <Logo compact={compact} />
          <div>
            <p style={{color: blue}}>Presented by <a className={styles.dsq} href={"https://datasparq.ai"}><img alt="Datasparq logo" src={logo} /></a></p>
            <p className={`${styles.introText} ${compact ? styles.introTextCompact : ""}`}>
              Interact with machine learning like never before!
              <br /><br />
              We've combined data science with data visualisation to break down big concepts into intuitive mini-games.
            </p>
          </div>
        </div>
        <Stack direction={compact ? "column" : "row"} spacing={2}>
          <Stack direction={"column"} spacing={2}>
            <Link title={"Read our Medium Blog"} href={"https://medium.com/datasparq-technology"} />

            <Card title={"Taxi Pricing Algorithm"} link={"/article/pricing_algorithm_v3"} height={78} />

            <Link title={"LinkedIn"} href={"https://www.linkedin.com/company/datasparq"} />

            <Card title={"Build Your ML Model"} link={"article/webcam_image_classifier"} height={180} variant={"game"}>
              <VizSpiderChart play={play}/>
            </Card>

            {/*<Card title={"What is PlayML?"} link={"https://medium.com/datasparq-technology/what-is-play-ml-78566a82faa0"} variant={"blog"} height={78}>*/}
            {/*</Card>*/}

            <Card title={"Supply Chain Optimisation"} link={"/article/supply_chain"} height={78} >
              {/*<VizChain play={play} />*/}
            </Card>
          </Stack>

          <Stack direction={"column"} spacing={2}>
            <Card title={"Elements of Engineering"} link={"/scrolly/elements-of-engineering-google/"} variant={"scrolly"} height={78} />


            <Card title={"Churn Identifier"} link={"/article/churn_identifier"} height={200} >
              <VizBars play={play}/>
            </Card>

            <Card title={"Predictive Maintenance"} link={"/article/predictive_maintenance"} height={200} >
              <VizIso play={play} />
            </Card>

          </Stack>

          <Stack direction={"column"} spacing={2}>
            <Card title={"Thompson Sampling"} link={"/article/thompson_sampling"} height={183} >
              <VizChain play={play} />
            </Card>

            {/*<Link title={"Twitter"} href={"https://twitter.com/DatasparqAI"} />*/}

            <Card title={"Business Map"} link={"/article/business_map"} variant={"game"} height={50} ></Card>

            <Card title={"Vehicle Routing"} link={"/article/vehicle_routing"} height={78} >
              {/*<VizCircles play={play} />*/}
            </Card>

            <Card title={"Airport Gate Assignment"} link={"/article/airport_gate_assignment"} height={183} >
              <VizParametric play={play} />
            </Card>
          </Stack>

        </Stack>
      </Stack>
      </Stack>
    </ThemeProvider>
  )

}

export default App;

function rescale() {
  if (window.innerWidth < 500) {  // compact
    document.getElementById("app").style.transform = ``
  } else if (window.innerWidth < maxWidth || (1.2 * window.innerHeight) > window.innerWidth) {
    // console.log("Below min width, rescale to", window.innerWidth/(100 + maxWidth))
    document.getElementById("app").style.transform = `scale(${window.innerWidth/(100 + maxWidth)})`
  } else {
    document.getElementById("app").style.transform = `scale(${window.innerHeight/(100 + maxHeight)})`
  }
}

// rezise listener for app - TODO: make hook
window.addEventListener('resize', rescale);
setTimeout(rescale, 1);
