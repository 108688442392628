import React, {useCallback, useEffect} from "react";
import { useSpring, animated as a } from 'react-spring'
import {makeStyles} from "@material-ui/core";
import {blue} from "../theme";

const VizChain = ({play}) => {
  const height = 183;

  const styles = makeStyles((theme) => ({
    root: {
      position: "absolute",
      top: "0px",
      width: "100%",
      height: height,
      overflow: "hidden",

      '& rect': {
        fill: blue,
      },
    },
  }))();

  const [{ st }, set] = useSpring(() => ({ st: 0 }));
  // const interpT = st.to(o => `translate(0,${50 * Math.cos(o/500)})`);
  // const interpH = st.to(o => 40 * Math.cos(o/500)**2);
  // const interpY = st.to(o => height - 40 * Math.cos(o/500)**2);
  const onWheel = useCallback(e => play ? set({ st: st.animation.to + e.deltaY }) : null);

  useEffect(() => {
    let interval;
    if (play) {
      interval = setInterval(() => set({ st: st.animation.to - 2 }), 10)
    }
    return () => {
      clearInterval(interval)
    }
  });

  document.body.addEventListener('wheel', onWheel);

  return (
    <svg className={styles.root}>
      <g id="face" transform={"rotate(-30) translate(-60, 40)"}>
        {
          [7, 14, 10, 8, 11, 6, 7].map((d, i)  => {
            return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18].map((e, j)  => {
              return (
                <a.rect key={i + "-" + j}
                        height={11}
                        width={d - 1}
                        // x={st.to(o => {
                        //   return 50 * Math.sin(o/500 + i/10)**2 + 20
                        // })}
                        // x={0}
                        x={st.to(o => {
                          return -120 * Math.sin(((o - 1000)*(0.2*i+0.5) + j*10)/500)**3 + j*d
                          // return -30 * (o+i)%10000/(10*i) + j*(d - 1)
                        })}
                        // y={st.to(o => {
                        //   return 50 * (o%1000)/100 + i*5 + 113
                        // })}
                        y={i*12 + 80}
                        opacity={i%3 === 0 ? 0.5 : 1} />
              )
            })
          })
        }
      </g>
    </svg>
  )
}

export default VizChain;
