import {makeStyles} from "@material-ui/core";
import {blue, grey, orange, white} from "../theme";
import React from "react";

export default function Card(props) {

  const styles = makeStyles((theme) => ({
    root: {
      background: theme.palette.primary.main,
      padding: "10px",
      textDecoration: "none",
      width: 178,
      cursor: "pointer",
    },
    title: {
      color: white,
      margin: 0,
      marginBottom: 11,
      fontSize: 11,
    },
    content: {
      position: "relative",
      background: white,
      // height: "calc(100% - 15px - 33px)",
      height: props.height ? props.height : 84,
      margin: 0,
    },
    contentTitle: {
      color: blue,
      margin: "11px",
      paddingTop: 11,
      fontFamily: "'Source Serif Pro', serif",
      fontSize: 24,
      zIndex: 999,
    }
  }))();

  let backgroundStyle, paragraphStyle;

  // default colours for variants
  if (props.variant === "game") {
    backgroundStyle = {background: orange}
  } else if (props.variant === "scrolly") {
    backgroundStyle = {background: grey}
  } else if (props.variant === "blog") {
    backgroundStyle = {background: grey}
  } else {
    backgroundStyle = {}
  }

  if (props.colour) {
    backgroundStyle = {background: props.colour}
  }

  if (backgroundStyle.background === grey) {
    paragraphStyle = {color: blue}
  }

  // const variantStyle = props.variant === "game" ? {background: orange} : props.variant === "scrolly" ? {background: grey} : props.variant === "blog" ? {background: grey} : {};
  // const variantStyleP = props.variant === "scrolly" ? {color: blue} : props.variant === "blog" ? {color: blue} : {};

  return (
    <div className={styles.root} style={backgroundStyle} onClick={() => window.open(props.link, "_self")}>
      <p className={styles.title} style={paragraphStyle}>{props.variant ? props.variant : "notebook"}</p>
      <div className={styles.content}>
        <h3 className={styles.contentTitle} >{props.title}</h3>
        {props.children}
      </div>
    </div>
  )
}
