import {makeStyles} from "@material-ui/core";
import {blue, lightBlue} from "../theme";
import React, {useEffect, useRef} from "react";

// d3.js bundles
import * as array from "d3-array";
import * as scale from "d3-scale";
import * as select from "d3-selection";
import * as shape from "d3-shape";
import * as transition from "d3-transition";
import * as ease from "d3-ease";


const data = [1, 1, 1, 1, 1, 1];

/*
* This component uses D3.js rather than react spring for creating animation
*/
const VizSpiderChart = ({ play }) => {

    const svg = useRef(null);

    const svgHeight = 180;
    const sectorCount = 6;

    const maxValue = Math.max(array.max(data.map((d) => d)), 1.2);
    const angleSlice = (Math.PI * 2) / data.length;

    const radius = svgHeight * 0.37;
    const rScale = scale.scaleLinear().domain([0, maxValue]).range([0, radius]);

    const styles = makeStyles((theme) => ({
        root: {
            position: "absolute",
            top: "0px",
            width: "100%",
            height: svgHeight,
            overflow: "hidden",
            "mix-blend-mode": "multiply",

            '& circle': {
                fill: blue,
            },
        },
    }))();

    useEffect(() => {
        let interval;
        const min = 0.25

        if (play) {
          interval = setInterval(() => {
              const dataUpdated = data.map(d => d * Math.random() * (1 - min) + min);

              const plot = select.selectAll(".points")
              plot
                .data(dataUpdated)
                .transition()
                .duration(2000)
                .ease(ease.easeCubicOut)
                .attr("cx", (d, i) => rScale(d) * Math.cos(angleSlice * i - Math.PI / 2))
                .attr("cy", (d, i) => rScale(d) * Math.sin(angleSlice * i - Math.PI / 2))


              const radarLine = shape
                .lineRadial()
                .curve(shape.curveLinearClosed)
                .radius((d) => rScale(d))
                .angle((d, i) => i * angleSlice);

              const path = select.select("#path")
              path
                .transition()
                .duration(2000)
                .ease(ease.easeCubicOut)
                .attr("d", radarLine(dataUpdated))

          }, 1000)
        }
        return () => {
          clearInterval(interval)
        }
   }, [data]);

    // D3.js only manages containers in SVG. It doesn't modify SVG itself.
    useEffect(() => {
        const main = select.select(svg.current);

        const container = main
          .append("g")
          .attr("width", "100%")
          .attr("height", "100%")
          .attr("transform", `translate(${main.node().getBoundingClientRect().width / 2}, ${svgHeight / 1.5})`);

        const grid = container.append("g");
        grid
          .selectAll()
          .data(array.range(1, sectorCount + 1).reverse())
          .enter()
          .append("circle")
          .classed("gridCircle", true)
          .attr("r", (d) => (radius / sectorCount) * d)
          .style("stroke", lightBlue)
          .style("stroke-width", (d, i) => 1)
          // .style("stroke-width", (d, i) => (i))
          .style("fill-opacity", 0);

        const radarLine = shape
           .lineRadial()
           .curve(shape.curveLinearClosed)
           .radius((d) => rScale(d))
           .angle((d, i) => i * angleSlice);

        const plot = container
          .append("g")
          .attr("fill", blue);

        plot
          .selectAll("circle")
          .data(data)
          .enter()
          .append("circle")
          .attr("r", 4)
          .attr("cx", (d, i) => rScale(d) * Math.cos(angleSlice * i - Math.PI / 2))
          .attr("cy", (d, i) => rScale(d) * Math.sin(angleSlice * i - Math.PI / 2))
          .classed("points", true)
          .style("fill-opacity", 1);

        plot
          .append("path")
          .attr("d", radarLine(data))
          .attr("stroke", blue)
          .attr("stroke-width", 3)
          .attr("fill", blue)
          .attr("fill-opacity", 0.3)
          .attr("id", "path");

        }, [data]);


    return (
        // React only manages the root SVG and doesn't control any other containers within it
        <svg className={ styles.root }  ref={ svg } id={"main"}>
        </svg>
    )
}

export default VizSpiderChart;