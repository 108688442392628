import React, {useCallback, useEffect} from "react";
import { useSpring, animated as a } from 'react-spring'
import {makeStyles} from "@material-ui/core";
import {blue, white, orange} from "../theme";

const VizIso = ({play}) => {
  const height = 200;

  const styles = makeStyles((theme) => ({
    root: {
      position: "absolute",
      top: "0px",
      width: "100%",
      height: height,
      overflow: "hidden",

      '& path': {
        strokeWidth: 1,
      },
    },
  }))();

  const [{ st }, set] = useSpring(() => ({ st: 0 }));
  // const interpT = st.to(o => `translate(0,${50 * Math.cos(o/500)})`);
  // const interpH = st.to(o => 40 * Math.cos(o/500)**2);
  // const interpY = st.to(o => height - 40 * Math.cos(o/500)**2);
  const onWheel = useCallback(e => play ? set({ st: st.animation.to + e.deltaY }) : null);

  useEffect(() => {
    let interval;
    if (play) {
      interval = setInterval(() => set({ st: st.animation.to - 2 }), 10)
    }
    return () => {
      clearInterval(interval)
    }
  });

  document.body.addEventListener('wheel', onWheel);

  return (
    <svg className={styles.root}>
      <g id="face">
        {
          [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map((d, i)  => {
            return (
              <a.g key={i}
                   transform={st.to(o => {
                     const a = (o % 1000 - (i -4) * 1000);
                     const x = 280 + (a/1000)*50
                     let y = 190 + (a/1000)*-30 + Math.sin(x / 50)*45
                     // if (i % 15 === 0) {
                     //   y += 100
                     // }
                     return `scale(0.4) translate(${x}, ${y})`
                   })}>

                <path d="M70 21L35.5 41L1 21L35.5 1L70 21Z" stroke={blue} strokeWidth={1} fill={white}/>
                <path d="M1 237.582V21L36 41.6521V263L1 237.582Z" stroke={blue} strokeWidth={1} fill={white}/>
                <path d="M70 239.905L36 263V40.0788L70 21V239.905Z" stroke={blue} strokeWidth={1} fill={blue}/>
              </a.g>
            )
          })
        }
      </g>
    </svg>
  )
}

export default VizIso;
