import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {white} from "../theme";

const useStyles = makeStyles((theme) => ({

  Logo: {
    margin: 0,
    marginTop: 150,
    '& h1': {
      margin: 0,
      fontSize: 108,
      fontFamily: "'Source Serif Pro', serif",
    }
  },
  LogoCompact: {
    marginTop: 20,
    '& h1': {
      fontSize: 60,
    }
  },
  LogoOutline: {
    '-webkit-text-fill-color': "#0000",
    '-webkit-text-stroke-width': "2px",
    '-webkit-text-stroke-color': white,
  }
}))


const Logo = ({ compact }) => {

  const styles = useStyles();

  return (
    <div className={`${styles.Logo} ${compact ? styles.LogoCompact : ""}`}>
      <h1><span style={{color: "#fff"}}>Play</span><span className={styles.LogoOutline}>ML</span></h1>
    </div>
  )
}

export default Logo;