import {makeStyles} from "@material-ui/core";
import {blue} from "../theme";
import React, {useCallback, useEffect} from "react";
import { useSpring, animated as a } from 'react-spring'

const VizBars = ({ play }) => {
  const height = 200;

  const styles = makeStyles((theme) => ({
    root: {
      position: "absolute",
      top: "0px",
      width: "100%",
      height: height,
      overflow: "hidden",

      '& rect': {
        fill: blue,
      },
    },
  }))();

  const [{ st }, set] = useSpring(() => ({ st: 0 }));
  const onWheel = useCallback(e => play ? set({ st: st.animation.to + e.deltaY }) : null);

  useEffect(() => {
    let interval;
    if (play) {
      interval = setInterval(() => set({ st: st.animation.to - 2 }), 10)
    }
    return () => {
      clearInterval(interval)
    }
  });

  document.body.addEventListener('wheel', onWheel);

  return (
    <svg className={styles.root} >
      <a.g id="face" >
        {
          [50, 60, 65, 69, 73, 74, 72, 70, 64, 59, 58, 57, 59, 67, 73].map((d, i)  => {
            return (
              <g key={i}>
                <a.rect
                        height={st.to(o => d * Math.cos(o/500 + i/5)**2 + 15)}
                        y={st.to(o => height - d * Math.cos(o/500 + i/5)**2 - 15)}
                        width={11} x={i * 12} />
                <a.rect height={st.to(o => (d + 8) * Math.cos(o/500 - 0.3 + i/3.8)**2 + 40)}
                        y={st.to(o => height - (d + 8) * Math.cos(o/500 - 0.3 + i/3.8)**2 - 40)}
                        width={11} x={i * 12} opacity={0.50} />
              </g>
            )
          })
        }
      </a.g>
    </svg>
  )
}

export default VizBars;
